
import { Vue, Component } from 'vue-property-decorator'
import { PageHeader } from '@/components/page-header'

@Component({
  components: {
    PageHeader,
  },
})

export default class InstitutionPage extends Vue {
}
