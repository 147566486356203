
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AuthorPage extends Vue {
  @Prop({ default: '', type: String }) titleClass!: string
  @Prop({ default: false }) noLogo!: boolean

  //
  // REACTIVE PROPERTIES
  //
  logoUrl = ''
  authorId = 0

  mounted() {
    // Loads logo dynamically
    import('@/assets/ams-logo.png').then(r => (this.logoUrl = r.default))
  }

  get internalTitleClass() {
    return `title ${this.titleClass}`.trim()
  }

  get lineClass() {
    return `${this.noLogo ? 'noLogo-line' : ''}`
  }
}
